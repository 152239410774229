import { createSlice } from "@reduxjs/toolkit"

export const productSlice = createSlice({
    name: "product",
    initialState: {
        productList: [],
        menuAddSearchValue: '',
        completeProductList: [],
        product: {},
        productId: '',
        brandId: null,
        menuAddRowsPerPage: 10,
        menuAddPageCount: 1,
        brandName: '',
        earningPcoins: 0,
        referralPcoins: 0,
        redeemPcoinsLimit: 0,
        paymentMethods: [],
        paymentMethodsIds: [],
        exportList: [],
        variations: [],
        existingVariations: [],
        productReviews: [],
        existingRedemptionCheck: false,
        options: [
            'Color',
            'Material',
            'Style',
            'Size',
        ],
        optionClicked: '',
        variant: {},
        variantImages: [],
        editorContent: '',
        descriptionEditorHtml: '',
        featuresEditorHtml: '',
        redemptionCorner: false,
        isFreshData: false,
        totalEntry: 0,
        menuAddTabSelected: 'isNewArrival',
        menuAddTabTitle: 'New Arrivals',
        menuAddTotalEntry: 0,
        menuAddFreshData: false,
        refreshment: false,
        refreshEditVariant: false,
        variantRefresh: false,
        editorLength: 0,
        isNewArrival: true,
        isTrending: false,
        isOnRedemption: false,
        isBestSelling: false,
        isSuperSaver: false,
        // errors
        brandError: '',
        categoryError: '',
        subCategoryError: '',
        earningPcoinsError: '',
        referralPcoinsError: '',
        redeemPcoinsLimitError: '',
        paymentError: '',
        descriptionError: '',
        featuresError: '',
        variationsError: '',
        variantError: '',
        variantError2: '',
        variantOptionError: '',
        imagesError: '',
        videoUrlError: '',
        productError: '',
        allVariantError: '',
    }, 
    reducers: {
        setEditorLength: (state, action) => {
            state.editorLength = action.payload
        },
        setCompleteProductList: (state, action) => {
            state.completeProductList = action.payload
        },
        setExistingRedemptionCheck: (state, action) => {
            state.existingRedemptionCheck = action.payload
        },
        setMenuAddTabSelected: (state, action) => {
            state.menuAddTabSelected = action.payload
        },
        setMenuAddRowsPerPage: (state, action) => {
            state.menuAddRowsPerPage = action.payload
        },
        setMenuAddPageCount: (state, action) => {
            state.menuAddPageCount = action.payload
        },
        setOptionClicked: (state, action) => {
            state.optionClicked = action.payload;
        },
        setRedemptionCorner: (state, action) => {
            state.redemptionCorner = action.payload
        },
        setOptions: (state, action) => {
            const updatedOptions = [...state.options];
            updatedOptions.push(action.payload);
            state.options = updatedOptions;
        },
        setProductList: (state, action) => {
            state.productList = action.payload
        },
        setVariantImages: (state, action) => {
            state.variantImages = action.payload
        },
        setRefreshEditVariant: (state) => {
            state.refreshEditVariant = !state.refreshEditVariant
        },
        setDescriptionEditorHtml: (state, action) => {
            state.descriptionEditorHtml = action.payload
        },
        setFeaturesEditorHtml: (state, action) => {
            state.featuresEditorHtml = action.payload
        },
        updateVariant: (state, action) => {
            const updatedVariant = action.payload;
            const index = state.variations.findIndex(variant => variant.id === updatedVariant.id);

            if (index !== -1) {
                state.variations[index] = updatedVariant
            }
        },
        removeVariant: (state, action) => {
            const removableVariant = action.payload;
            const index = state.variations.findIndex(variant => variant.id === removableVariant.id);

            if (index !== -1) {
                state.variations.splice(index, 1)
            }
        },
        removePayment: (state, action) => {
            const removablePayment = action.payload;
            const index = state.paymentMethods.findIndex(payment => payment.id === removablePayment.id);

            if (index !== -1) {
                state.paymentMethods.splice(index, 1);
                
            }
            // console.log('state.paymentMethods', state.paymentMethods)
        },
        removePaymentId: (state, action) => {
            const removableId = action.payload;
            const newPaymentMethodsIds = state?.paymentMethodsIds?.filter(id => id !== removableId);
            state.paymentMethodsIds = newPaymentMethodsIds;

        },
        addPaymentId: (state, action) => {
            const newId = action.payload;
            const idExists = state?.paymentMethodsIds?.some(id => id === newId);
            if (!idExists) {
                const newPaymentMethodsIds = [...(state?.paymentMethodsIds || []), action.payload];
                state.paymentMethodsIds = newPaymentMethodsIds;
            }
        },
        addPayment: (state, action) => {
            const newPayment = action.payload;
            const nameExists = state.paymentMethods.some((payment) => payment.name === newPayment.name);
            if (!nameExists) {
                state.paymentMethods = [...state.paymentMethods, action.payload]
            }
        },
        setEarningPcoins: (state, action) => {
            state.earningPcoins = action.payload
        },
        setMenuAddTotalEntry: (state, action) => {
            state.menuAddTotalEntry = action.payload
        },
        setReferralPcoins: (state, action) => {
            state.referralPcoins = action.payload
        },
        setMenuAddTabTitle: (state, action) => {
            state.menuAddTabTitle = action.payload
        },
        setRedeemPcoinsLimit: (state, action) => {
            state.redeemPcoinsLimit = action.payload
        },
        setPaymentMethods: (state, action) => {
            state.paymentMethods = action.payload
        },
        setPaymentMethodsIds: (state, action) => {
            state.paymentMethodsIds = action.payload
        },
        setProduct: (state, action) => {
            state.product = action.payload
        },
        setProductId: (state, action) => {
            state.productId = action.payload
        },
        setMenuAddSearchValue: (state, action) => {
            state.menuAddSearchValue = action.payload
        },
        setBrandId: (state, action) => {
            state.brandId = action.payload
        },
        setBrandName: (state, action) => {
            state.brandName = action.payload
        },
        setVariations: (state, action) => {
            state.variations = action.payload
        },
        setVariant: (state, action) => {
            state.variant = action.payload
        },
        setEditorContent: (state, action) => {
            state.editorContent = action.payload
        },
        setEditorHtml: (state, action) => {
            state.editorHtml = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setMenuAddFreshList: (state, action) => {
            state.menuAddFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setRefreshment: (state, action) => {
            state.refreshment = action.payload
        },
        setVariantRefresh: (state, action) => {
            state.variantRefresh = !state.variantRefresh
        },
        // errors
        setBrandError: (state, action) => {
            state.brandError = action.payload
        },
        setCategoryError: (state, action) => {
            state.categoryError = action.payload
        },
        setSubCategoryError: (state, action) => {
            state.subCategoryError = action.payload
        },
        setEarningPcoinsError: (state, action) => {
            state.earningPcoinsError = action.payload
        },
        setReferralPcoinsError: (state, action) => {
            state.referralPcoinsError = action.payload
        },
        setRedeemPcoinsLimitError: (state, action) => {
            state.redeemPcoinsLimitError = action.payload
        },
        setPaymentError: (state, action) => {
            state.paymentError = action.payload
        },
        setVariationsError: (state, action) => {
            state.variationsError = action.payload
        },
        setDescriptionError: (state, action) => {
            state.descriptionError = action.payload
        },
        setVariantError: (state, action) => {
            state.variantError = action.payload
        },
        setVariantError2: (state, action) => {
            state.variantError2 = action.payload
        },
        setFeaturesError: (state, action) => {
            state.featuresError = action.payload
        },
        setProductReviews: (state, action) => {
            state.productReviews = action.payload;
        },
        setExistingVariations: (state, action) => {
            state.existingVariations = action.payload
        },
        setImagesError: (state, action) => {
            state.imagesError = action.payload
        },
        setVideoUrlError: (state, action) => {
            state.videoUrlError = action.payload
        },
        setProductError: (state, action) => {
            state.productError = action.payload
        },
        setVariantOptionError: (state, action) => {
            state.variantOptionError = action.payload
        },
        setAllVariantError: (state, action) => {
            state.allVariantError = action.payload;
        },
        setNewArrival: (state, action) => {
            state.isNewArrival = action.payload;
        },
        setTrending: (state, action) => {
            state.isTrending = action.payload;
        },
        setBestSelling: (state, action) => {
            state.isBestSelling = action.payload;
        },
        setRedemption: (state, action) => {
            state.isOnRedemption = action.payload;
        },
        setSuperSaver: (state, action) => {
            state.isSuperSaver = action.payload;
        }
    }
})


export const {
    setCompleteProductList,
    setOptionClicked,
    setEditorLength,
    setMenuAddFreshList,
    setMenuAddTotalEntry,
    setVariantOptionError,
    setProductList,
    setProduct,
    setFreshList,
    setTotalEntry,
    setVariations,
    setMenuAddPageCount,
    setMenuAddRowsPerPage,
    setVariant,
    updateVariant,
    setProductId,
    setEditorContent,
    removeVariant,
    setBrandId, 
    setEarningPcoins,
    setMenuAddTabSelected,
    setRedeemPcoinsLimit,
    setPaymentMethods,
    setBrandName,
    setEditorHtml,
    setRefreshment,
    setDescriptionEditorHtml,
    setMenuAddTabTitle,
    setFeaturesEditorHtml,
    setRefreshEditVariant,
    setBrandError,
    setCategoryError,
    setSubCategoryError,
    setEarningPcoinsError,
    setRedeemPcoinsLimitError,
    setPaymentError,
    setVariationsError,
    setDescriptionError,
    setVariantError,
    setVariantError2,
    setFeaturesError,
    setProductReviews,
    setExistingVariations,
    setImagesError,
    setVideoUrlError,
    setVariantImages,
    setPaymentMethodsIds,
    removePayment,
    addPayment,
    removePaymentId,
    addPaymentId,
    setProductError,
    setOptions,
    setReferralPcoins,
    setReferralPcoinsError,
    setVariantRefresh,
    setRedemptionCorner,
    setExistingRedemptionCheck,
    setAllVariantError,
    setBestSelling,
    setRedemption,
    setTrending,
    setNewArrival,
    setSuperSaver,
    setMenuAddSearchValue
} = productSlice.actions

export const selectProductList = (state) => state.product.productList;
export const selectCompleteProductList = (state) => state.product.completeProductList;
export const selectProduct = (state) => state.product.product;
export const selectVariations = (state) => state.product.variations;
export const selectVariant = (state) => state.product.variant;
export const selectFreshData = (state) => state.product.isFreshData;
export const selectMenuAddFreshData = (state) => state.product.menuAddFreshData;
export const selectTotalEntry = (state) => state.product.totalEntry;
export const selectMenuAddTotalEntry = (state) => state.product.menuAddTotalEntry;
export const selectProductId = (state) => state.product.productId;
export const selectEditorContent = (state) => state.product.editorContent;
export const selectBrandId = (state) => state.product.brandId;
export const selectEarningPcoins = (state) => state.product.earningPcoins;
export const selectReferralPcoins = (state) => state.product.referralPcoins;
export const selectRedeemPcoinsLimit = (state) => state.product.redeemPcoinsLimit;
export const selectPaymentMethods = (state) => state.product.paymentMethods;
export const selectPaymentMethodsIds = (state) => state.product.paymentMethodsIds;
export const selectBrandName = (state) => state.product.brandName;
export const selectMenuAddRowsPerPage = (state) => state.product.menuAddRowsPerPage;
export const selectMenuAddPageCount = (state) => state.product.menuAddPageCount;
export const selectMenuAddTabSelected = (state) => state.product.menuAddTabSelected;
export const selectEditorHtml = (state) => state.product.editorHtml;
export const selectRefreshment = (state) => state.product.refreshment;
export const selectDescriptionEditorHtml = (state) => state.product.descriptionEditorHtml;
export const selectFeaturesEditorHtml = (state) => state.product.featuresEditorHtml;
export const selectRefreshEditVariant = (state) => state.product.refreshEditVariant;
export const selectOptions = (state) => state.product.options;
export const selectOptionClicked = (state) => state.product.optionClicked;
export const selectVariantRefresh = (state) => state.product.variantRefresh;
export const selectRedemptionCorner = (state) => state.product.redemptionCorner;
export const selectExistingRedemptionCheck = (state) => state.product.existingRedemptionCheck;
export const selectEditorLength = (state) => state.product.editorLength;
export const selectNewArrival = (state) => state.product.isNewArrival;
export const selectTrending = (state) => state.product.isTrending;
export const selectBestSelling = (state) => state.product.isBestSelling;
export const selectSuperSaver = (state) => state.product.isSuperSaver;
export const selectRedemption = (state) => state.product.isOnRedemption;
export const selectMenuAddSearchValue = (state) => state.product.menuAddSearchValue;
export const selectMenuAddTabTitle = (state) => state.product.menuAddTabTitle;

// errors
export const selectBrandError = (state) => state.product.brandError;
export const selectCategoryError = (state) => state.product.categoryError;
export const selectSubCategoryError = (state) => state.product.subCategoryError;
export const selectEarningPcoinsError = (state) => state.product.earningPcoinsError;
export const selectReferralPcoinsError = (state) => state.product.referralPcoinsError;
export const selectRedeemPcoinsLimitError = (state) => state.product.redeemPcoinsLimitError;
export const selectPaymentError = (state) => state.product.paymentError;
export const selectDescriptionError = (state) => state.product.descriptionError;
export const selectVariationsError = (state) => state.product.variationsError;
export const selectVariantError = (state) => state.product.variantError;
export const selectVariantError2 = (state) => state.product.variantError2;
export const selectFeaturesError = (state) => state.product.featuresError;
export const selectProductReviews = (state) => state.product.productReviews;
export const selectExistingVariations = (state) => state.product.existingVariations;
export const selectImagesError = (state) => state.product.imagesError;
export const selectVideoUrlError = (state) => state.product.videoUrlError;
export const selectVariantImages = (state) => state.product.variantImages;
export const selectProductError = (state) => state.product.productError;
export const selectVariantOptionError = (state) => state.product.variantOptionError;
export const selectAllVariantError = (state) => state.product.allVariantError;

export default productSlice.reducer
